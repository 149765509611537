import { template as template_44795e755e85449cac0449e4a126711f } from "@ember/template-compiler";
const FKControlMenuContainer = template_44795e755e85449cac0449e4a126711f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
