import { template as template_484613c0db6e41fabf350b42c8ec0f7a } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKCol from "discourse/form-kit/components/fk/col";
const FKRow = template_484613c0db6e41fabf350b42c8ec0f7a(`
  <div class="form-kit__row" ...attributes>
    {{yield (hash Col=FKCol)}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKRow;
