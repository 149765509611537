import { template as template_a91f38517693448292aaa8e944cc6df7 } from "@ember/template-compiler";
const FKText = template_a91f38517693448292aaa8e944cc6df7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
